import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "header"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("@/assets/imgs/logo.png"),
      "alt": "巨石创投"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/home');
      }
    }
  }), _c('el-menu', {
    staticStyle: {
      "flex-shrink": "0"
    },
    attrs: {
      "mode": "horizontal",
      "default-active": _vm.$route.name.split('.')[0],
      "ellipsis": false
    },
    on: {
      "select": _setup.handleSelect
    }
  }, [_c('el-menu-item', {
    attrs: {
      "index": "home"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/home');
      }
    }
  }, [_vm._v("首页")]), _c('el-menu-item', {
    attrs: {
      "index": "about"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/about');
      }
    }
  }, [_vm._v("关于我们")]), _c('el-menu-item', {
    attrs: {
      "index": "investment"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/investment');
      }
    }
  }, [_vm._v("投资实力")]), _c('el-menu-item', {
    attrs: {
      "index": "information"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/information');
      }
    }
  }, [_vm._v("信息公示")]), _c('el-menu-item', {
    attrs: {
      "index": "education"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/education');
      }
    }
  }, [_vm._v("投资者教育")]), _c('el-menu-item', {
    attrs: {
      "index": "contact"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/contact');
      }
    }
  }, [_vm._v("联系我们")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };