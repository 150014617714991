import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";

import { ref, onMounted } from 'vue';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var main = ref();
    var isMobile = function isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    onMounted(function () {
      if (isMobile()) {
        console.log('isMobile');
        var scale = document.documentElement.clientWidth / 960;
        main.value.style = "transform: scale(".concat(scale, ");transform-origin: top left;");
      }
    });
    return {
      __sfc: true,
      main: main,
      isMobile: isMobile
    };
  }
};