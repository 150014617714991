export default {
  __name: 'PageFooter',
  setup: function setup(__props) {
    var open = function open(url) {
      window.open(url);
    };
    return {
      __sfc: true,
      open: open
    };
  }
};